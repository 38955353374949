import React from 'react'
import WagmiProvider from './WagmiProvider'

const Providers = ({ children }) => {
    return (
        <WagmiProvider>{children}</WagmiProvider>
    )
}

export default Providers
