import { CHAIN_ID, CHAIN_NAME, DECIMALS, EXPLORER_URL, RPC, SYMBOL } from "../constants";

export const BSTChain = {
  id: CHAIN_ID,
  name: CHAIN_NAME,
  network: CHAIN_NAME,
  nativeCurrency: {
    decimals: DECIMALS,
    name: CHAIN_NAME,
    symbol: SYMBOL,

  },
  rpcUrls: {
    public: { http: [RPC] },
    default: { http: [RPC] },
  },
  blockExplorers: {
    default: { name: CHAIN_NAME, url: EXPLORER_URL },
    etherscan: { name: CHAIN_NAME, url: EXPLORER_URL },
  },
  testnet: false,
};
