import React from "react";
import Main from "./Main";
import Newsletter from "../../components/Newsletter";

const YourTrips = () => {
    return (
        <>
            <Main />
            <Newsletter />
        </>
    );
};

export default YourTrips;
