import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { EthereumClient, w3mConnectors } from "@web3modal/ethereum";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Web3Modal } from "@web3modal/react";
import { BSTChain } from "./chain";

const chains = [BSTChain];
const projectId = "07d5d29f6bc1a56afdce3fb0ffebad05";

const { publicClient } = configureChains(chains, [publicProvider()]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient,
});

createWeb3Modal({ wagmiConfig, projectId, chains });

const ethereumClient = new EthereumClient(wagmiConfig, chains);

const WagmiProvider = ({ children }) => {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={BSTChain}
        enableNetworkView={false}
      />
    </>
  );
};

export default WagmiProvider;
